import { Component, createRef } from 'react'
import { connect } from 'react-redux'

import config from '../config'
import AlbumSlideShowLargeImagePanel from './AlbumSlideShowLargeImagePanel'
import AlbumSlideShowCarouselImage from './AlbumSlideShowCarouselImage'
import { addAlbumImageTmpUrl, addAlbumImageTmpUrlReload } from '../actionTypes/actionTypes'
import loadinggif from '../assets/loading.gif';

class AlbumSlideShow extends Component {
  constructor(props) {
    super(props)
    const { largeImageData, albumData }= props
    this.carouselImages = {}
    this.currentImageIdx = 0;
    this.state = {
      display: true,
      currentImageFilename: null,
      largeImageCssClass: '',
      siblingImagesLoaded: false,
      maxInnerContainerScroll: 0,
      lazyLoadedImage: 0
    }
    this.savedBodyYpositon = 0;
    this.currentLargeImageData = largeImageData
    this.displayLargerImage = this.displayLargerImage.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.mobileEnlargeImageTimeout = null
    this.cursorTimeout = null
    this.autoFlipInterval = null
    this.startFlipOrEnlarge = this.startFlipOrEnlarge.bind(this)
    this.carouselScroll = this.carouselScroll.bind(this)
    this.startFlipLeft = 0
    this.imagePoint = null
    this.lastDiffPx = 0
    this.prevImage = null
    this.nextImage = null
    this.fullsizeMode = false
    this.slideShowContainer = null;
    this.requestedImages = []
    this.slideShowInnerContainer = createRef()
    this.loadNextCarouselImage = this.loadNextCarouselImage.bind(this)
    this.flipLargeImage = this.flipLargeImage.bind(this)
    this.finishLargeImageFlip = this.finishLargeImageFlip.bind(this)
    this.hideSlideShow = this.hideSlideShow.bind(this)

    this.loadingImg = <img src={loadinggif} />
    this.lastLargeImageData = null;
    this.lastlargeImageScroller = null;
  }

  async displayLargerImage(e, imageData) {
    if (e) {
      e.stopPropagation()
    }

    const {albumData, imageTempUrlCache, albumKey} = this.props
    this.state['siblingImagesLoaded'] = false;

    const albumImagePath =  albumData['pagename'] + '/' + imageData['sizes'][albumData['large_size']]['size_filename']
    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
    })

    if (!imageTempUrlCache[albumImagePath] && this.requestedImages.indexOf(albumImagePath) == -1) {
      this.requestedImages.push(albumImagePath)

      const apiurl = config['pyalbum_host'] + '/img/' + albumImagePath
      const response = await fetch(apiurl, {
        headers: myHeaders
      })
      const imageBlob = await response.blob()
      const imageObjectUrl = URL.createObjectURL(imageBlob)
      this.props.addAlbumImageTmpUrl(albumImagePath, imageObjectUrl)

    }
    const imageIdx = Object.keys(albumData.photos).indexOf(imageData['filename'])
    let startPreloadIndex = 0;
    if (imageIdx > 2) {
      startPreloadIndex  = imageIdx - 2
    }
    const albumImagesData = Object.values(albumData.photos)
    this.prevImageData = null;
    this.nextImageData = null;
    let siblingImagesLoaded = true;
    let prevImagePath = null;
    let nextImagePath = null;
    if (imageIdx > 0) {
      this.prevImageData = albumImagesData[imageIdx - 1]
      prevImagePath = albumData['pagename'] + '/' + this.prevImageData['sizes'][albumData['large_size']]['size_filename']
      if (!imageTempUrlCache[prevImagePath]) {
        siblingImagesLoaded = false
      }
    }
    if (imageIdx < (albumImagesData.length-1)) {
      this.nextImageData = albumImagesData[imageIdx + 1]
      nextImagePath = albumData['pagename'] + '/' + this.nextImageData['sizes'][albumData['large_size']]['size_filename']
      if (!imageTempUrlCache[nextImagePath]) {
        siblingImagesLoaded = false
      }
    }
    const preLoadImagesList = albumImagesData.slice(startPreloadIndex, imageIdx+3)
    if (preLoadImagesList) {
      preLoadImagesList.forEach(preLoadImageData => {
        const albumPreloadImagePath = albumData['pagename'] + '/' + preLoadImageData['sizes'][albumData['large_size']]['size_filename']
        if (this.requestedImages.indexOf(albumPreloadImagePath) > -1) {
          return;
        }

        this.requestedImages.push(albumPreloadImagePath)

        if (!imageTempUrlCache[albumPreloadImagePath]) {
          const apiurl = config['pyalbum_host'] + '/img/' + albumPreloadImagePath
          fetch(apiurl, {
            headers: myHeaders
          })
          .then(response => response.blob())
          .then(imageBlob => {
            const imageObjectUrl = URL.createObjectURL(imageBlob)
            this.props.addAlbumImageTmpUrl(albumPreloadImagePath, imageObjectUrl)
            if (siblingImagesLoaded === false
                && (!prevImagePath || imageTempUrlCache[prevImagePath])
                && (!nextImagePath || imageTempUrlCache[nextImagePath])) {
              siblingImagesLoaded = true;
              this.setState({
                siblingImagesLoaded
              })
            }
          })
        }
      })
    }

    this.currentImageIdx = imageIdx
    this.currentLargeImageData = imageData
    this.setState({
      currentImageFilename: imageData['filename'],
      selectedCarouselImageFilename: imageData['filename'],
      siblingImagesLoaded
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.currentLargeImageData && this.props['largeImageData'] != null &&
          ((this.state['display'] == false && prevState['display'] === false)
            || (prevProps['largeImageData'] == null))) {

      this.displayLargerImage(null, this.props['largeImageData'])
      this.setState({
        display: true
      })
      this.setupKeyTracking()
      if (!document.body.classList.contains('slideshow-open')) {
        this.savedBodyYpositon = window.scrollY
        document.body.style.top = (-1 * this.savedBodyYpositon) + 'px';
        document.body.classList.add('slideshow-open');
        // document.body.style.top
      }
    }
    else if (this.props['largeImageData'] === null && prevProps['largeImageData'] != null && this.state['display'] === true) {
      this.hideSlideShow()
    }
  }

  hideSlideShow() {
    window.setTimeout(() => {
      this.setState({
        display: false,
      })
      if (this.slideShowContainer && this.slideShowContainer.classList.contains('mobile-full-size')) {
        this.slideShowContainer.classList.remove('mobile-full-size')
        this.fullsizeMode = false
      }
      if (document.body.classList.contains('slideshow-open')) {
        document.body.classList.remove('slideshow-open');
        window.scrollTo(0, parseInt(this.savedBodyYpositon || '0'));
      }
      this.unsetKeyTracking()
      this.currentLargeImageData = null;
      this.props.clearLargeImage()
    }, 100)
  }

  loadLargeImageByIndex() {
    if (this.cursorTimeout) {
      window.clearTimeout(this.cursorTimeout)
      this.cursorTimeout = null;
    }
    const {albumData, imageTempUrlCache, albumKey} = this.props
    const albumImagesData = Object.values(albumData.photos)
    let siblingImagesLoaded = true;

    const albumImageKeys = Object.keys(albumData.photos)
    const currentImageKey = albumImageKeys[this.currentImageIdx]
    this.currentLargeImageData = albumData.photos[currentImageKey];

    if (this.currentImageIdx > 0) {
      this.prevImageData = albumImagesData[this.currentImageIdx - 1]
      const prevImagePath = albumData['pagename'] + '/' + this.prevImageData['sizes'][albumData['large_size']]['size_filename']
      if (!imageTempUrlCache[prevImagePath]) {
        siblingImagesLoaded = false
      }
    }
    if (this.currentImageIdx < (albumImagesData.length-1)) {
      this.nextImageData = albumImagesData[this.currentImageIdx + 1]
      const nextImagePath = albumData['pagename'] + '/' + this.nextImageData['sizes'][albumData['large_size']]['size_filename']
      if (!imageTempUrlCache[nextImagePath]) {
        siblingImagesLoaded = false
      }
    }
    this.setState({
      selectedCarouselImageFilename: currentImageKey,
      siblingImagesLoaded

    })

    this.cursorTimeout = window.setTimeout(() => {
      this.displayLargerImage(null, this.currentLargeImageData)
    }, 200);
  }

  goToPreviousImage() {
    if (this.currentImageIdx > 0) {
      this.currentImageIdx = this.currentImageIdx - 1;
      this.loadLargeImageByIndex()
    }
  }

  goToNextImage() {
    const {albumData} = this.props
    const albumImageKeys = Object.keys(albumData.photos)
    if ((this.currentImageIdx+1) < albumImageKeys.length) {
      this.currentImageIdx = this.currentImageIdx + 1;
      this.loadLargeImageByIndex()

    }
   /*
    if (this.cursorTimeout) {
      window.clearTimeout(this.cursorTimeout)
      this.cursorTimeout = null;
    }

    // const {currentImageFilename} = this.state
    // const imageIdx = albumPhotoKeys.indexOf(currentImageFilename)

    if ((this.currentImageIdx+1) < albumImageKeys.length) {
      this.currentImageIdx = this.currentImageIdx + 1;
      const currentImageKey = albumImageKeys[this.currentImageIdx]
      this.currentLargeImageData = albumData.photos[currentImageKey];

      this.setState({
        selectedCarouselImageFilename: currentImageKey,
        // siblingImagesLoaded: this.checkSiblingImagesLoaded()
      })
      this.cursorTimeout = window.setTimeout(() => {
        this.displayLargerImage(null, this.currentLargeImageData)
      }, 300);
    }*/
  }

  handleKeyPress(e) {

    e.preventDefault();
    e.stopPropagation();
    // left/top
    if (e.keyCode == 37 || e.keyCode == 38) {
      this.goToPreviousImage()
    }
    //right/down
    else if (e.keyCode == 39 || e.keyCode == 40) {
      this.goToNextImage()
    }
    //esc
    else if (e.keyCode == 27) {
      this.hideSlideShow()
    }
  }

  setupKeyTracking() {
    document.addEventListener('keydown', this.handleKeyPress, false)
  }

  unsetKeyTracking() {
    document.removeEventListener('keydown', this.handleKeyPress, false)
  }

  mobileEnlargeImage(e) {
    if (this.mobileEnlargeImageTimeout === null) {
      this.mobileEnlargeImageTimeout = window.setTimeout(() => {
        this.mobileEnlargeImageTimeout = null
      }, 200)
    }
    else if (this.mobileEnlargeImageTimeout !== null) {
      window.clearTimeout(this.mobileEnlargeImageTimeout)

      this.mobileEnlargeImageTimeout = window.setTimeout(this.toggleMobileEnlargeImage.bind(this, e), 200)
    }
  }

  toggleMobileEnlargeImage(e) {
    const {albumData} = this.props

    this.mobileEnlargeImageTimeout = null
    // console.log('------')
    // console.log(e.target)
    // console.log(this.currentLargeImageData)
    // console.log(e.touches)
    // console.log(parseInt(e.touches[0].clientX))
    // console.log(parseInt(e.touches[0].clientY))
    const imageTopOffset = e.target.getBoundingClientRect().top
    const targetParent = e.target.parentElement
    this.slideShowContainer = targetParent.parentElement.parentElement.parentElement
    const largeImageSize = this.currentLargeImageData['sizes'][albumData['large_size']]
    const shrunkWidthRatio = largeImageSize['width'] / e.target.clientWidth
    const shrunkHeightRatio = largeImageSize['height'] / e.target.clientHeight
    const parentMidX = parseInt(targetParent.clientWidth / 2)
    const parentMidY = parseInt(targetParent.clientHeight / 2)

    // console.log(shrunkWidthRatio)
    // console.log(shrunkHeightRatio)
    let scrollX = parseInt(e.touches[0].clientX) * shrunkWidthRatio
    let scrollY = (parseInt(e.touches[0].clientY) - imageTopOffset) * shrunkHeightRatio
    if ((scrollX - parentMidX) > 0) {
      scrollX = scrollX - parentMidX
    }
    else {
      scrollX = 0
    }
    // console.log(scrollX)
    // console.log(scrollY)
    if ((scrollY - parentMidY) > 0) {
      scrollY = scrollY - parentMidX
    }
    else {
      scrollY = 0
    }
    // console.log(scrollX)
    // console.log(scrollY)
    // console.log('-----parentmid-')
    // console.log(parentMidX)
    // console.log(parentMidY)

    // e.target.clientHeight;
    if (this.slideShowContainer.classList.contains('mobile-full-size')) {
      this.slideShowContainer.classList.remove('mobile-full-size')
      this.fullsizeMode = false
    }
    else {
      this.slideShowContainer.classList.add('mobile-full-size')
      this.fullsizeMode = true
    }

    e.target.parentElement.scrollTo(parseInt(scrollX), parseInt(scrollY));
    this.startFlipLeft = 0;
    this.lastDiffPx = 0;
    this.imagePoint = null;
  }

  startFlipOrEnlarge(e) {
    e.stopPropagation();
    if (e.target.src.indexOf('.gif') > -1) {
      return;
    }
    this.startFlipLeft = e.touches[0].clientX;
    this.lastDiffPx = 0;
    this.mobileEnlargeImage(e)
  }

  flipLargeImage(e) {
    if (e.target.src.indexOf('.gif') > -1) {
      return;
    }
    if (this.mobileEnlargeImageTimeout) {
      window.clearTimeout(this.mobileEnlargeImageTimeout)
      this.mobileEnlargeImageTimeout = null;
    }
    if (!this.fullsizeMode) {
      this.lastDiffPx = e.touches[0].clientX - this.startFlipLeft
      const largeImageScrollStart = -1 * e.target.parentElement.clientWidth
      const moveX = largeImageScrollStart + this.lastDiffPx
      let maxScrollRight = largeImageScrollStart
      let minScrollLeft = largeImageScrollStart
      if (this.nextImageData) {
        maxScrollRight = (largeImageScrollStart * 2)
      }
      if (this.prevImageData) {
        minScrollLeft = 0
      }

      if ( moveX >= maxScrollRight && moveX <= minScrollLeft) {
        e.target.parentElement.parentElement.style.left =  moveX + 'px'
      }
    }
  }

  finishLargeImageFlip(e) {
    if (e.target.src.indexOf('.gif') > -1) {
      return;
    }
    if (!this.fullsizeMode) {
      const largeImageScrollStart = -1 * e.target.parentElement.clientWidth
      if (Math.abs(this.lastDiffPx) >= e.target.width * .4) {

        if (this.lastDiffPx > 0 && this.prevImageData) {
          if ((largeImageScrollStart + this.lastDiffPx) > -50) {
            e.target.parentElement.parentElement.style.left = '0'
            this.goToPreviousImage()
          }
          else {
            this.autoFlipInterval = window.setInterval(() => {
              const moveX = largeImageScrollStart + this.lastDiffPx
              if (moveX < 0) {
                this.lastDiffPx+=20;
                e.target.parentElement.parentElement.style.left = moveX + 'px'
              }
              else {
                window.clearInterval(this.autoFlipInterval)
                this.autoFlipInterval = null;
                this.goToPreviousImage()
              }
            }, 20);
          }
        }
        else if (this.lastDiffPx < 0 && this.nextImageData)  {
          const nextImagePos = (largeImageScrollStart * 2);
          if ((nextImagePos - (largeImageScrollStart + this.lastDiffPx)) > -50) {
            e.target.parentElement.parentElement.style.left  = nextImagePos + 'px'
            this.goToNextImage()
          }
          else {
            this.autoFlipInterval = window.setInterval(() => {
              const moveX = largeImageScrollStart + this.lastDiffPx
              if (moveX > nextImagePos) {
                this.lastDiffPx-=20;
                e.target.parentElement.parentElement.style.left = moveX + 'px'
              }
              else {
                window.clearInterval(this.autoFlipInterval)
                this.autoFlipInterval = null;
                this.goToNextImage()
              }
            }, 20);
          }

        }
        else {
           e.target.parentElement.parentElement.style.left = largeImageScrollStart + 'px'
        }
      }
      else {
        let moveDirection = 20;
        if (this.lastDiffPx > 0) {
          moveDirection = -20;
        }

        this.autoFlipInterval = window.setInterval(() => {
          const moveX = largeImageScrollStart + this.lastDiffPx
          if (Math.abs(this.lastDiffPx) > 21) {
            this.lastDiffPx = this.lastDiffPx + moveDirection;
            e.target.parentElement.parentElement.style.left = moveX + 'px'
          }
          else {
            window.clearInterval(this.autoFlipInterval)
            this.autoFlipInterval = null;
            e.target.parentElement.parentElement.style.left  = largeImageScrollStart + 'px'
          }
        }, 20);

      }
    }
  }

  loadNextCarouselImage() {
    const {albumData} = this.props
    const {lazyLoadedImage} = this.state
    // console.log('sdfsdf-')
    if (Object.keys(albumData['photos']).length > lazyLoadedImage) {
      // console.log('lazyLoadImage' + lazyLoadedImage)
      this.setState({
        'lazyLoadedImage': lazyLoadedImage + 1
      })
      // console.log('lazyLoadImage' + this.state.lazyLoadedImage)
    }
  }

  renderImageCarousel() {
    const {albumData} = this.props
    const {lazyLoadedImage, maxInnerContainerScroll} = this.state
    // console.log('carousel regen')
    return Object.values(albumData.photos).map((imageData, imgIdx) => {
      // console.log(this.state['currentImageFilename']);
      return (
        <AlbumSlideShowCarouselImage
          imageData={imageData}
          albumData={albumData}
          loadImageState={imgIdx <= lazyLoadedImage}
          isSelected={this.props['selection'].indexOf(imageData['filename']) > -1}
          // isCurrentImage={this.state['currentImageFilename'] == imageData['filename']}
          isCurrentImage={this.state['selectedCarouselImageFilename'] == imageData['filename']}
          maxInnerContainerScroll={maxInnerContainerScroll}
          displayLargerImage={this.displayLargerImage}
          loadNextCarouselImage={this.loadNextCarouselImage}
          key={'carousel-img-' + imageData['filename']}
          />
      )
    })
  }

  componentWillUnmount() {
    // console.log('unmounted')
    if (document.body.classList.contains('slideshow-open')) {
      // document.body.classList.remove('slideshow-open');
    }
  }

  carouselScroll(e) {
    const {maxInnerContainerScroll} = this.state
    const parentEleWidth = this.slideShowInnerContainer.current.parentElement.clientWidth
    const innerContainerScrollLeft = this.slideShowInnerContainer.current.scrollLeft + parentEleWidth
    if (innerContainerScrollLeft > (maxInnerContainerScroll + 100)) {
      this.setState({
        maxInnerContainerScroll: innerContainerScrollLeft
      })
      // console.log(innerContainerScrollLeft);
    }
  }

  render() {
    const { albumData, imageTempUrlCache } = this.props;
    const { display, siblingImagesLoaded, imageFullSize } = this.state
    const displayCss = {
      'display': 'none'
    }

    let largeImageSrc = null
    let largeImageData = null
    const largeImageDivs = []
    let previousImg = null
    let currentImg = this.loadingImg
    let nextImg = null
    let carousel = <></>
    let imageScrollerKey = ''
    if (this.currentLargeImageData && display) {
      const imagePath = albumData['pagename'] + '/' + this.currentLargeImageData['sizes'][albumData['large_size']]['size_filename']
      largeImageData = this.currentLargeImageData
      imageScrollerKey += largeImageData['filename']
      displayCss['display'] = 'block'
      if (imageTempUrlCache[imagePath]) {
        largeImageSrc = imageTempUrlCache[imagePath]
        currentImg = (<img
          src={largeImageSrc}
          onClick={(e) => e.stopPropagation()}
          onTouchStart={this.startFlipOrEnlarge}
          onTouchMove={this.flipLargeImage}
          onTouchEnd={this.finishLargeImageFlip}
          />
        )
      }

      if (this.prevImageData && siblingImagesLoaded) {
        const prevImagePath = albumData['pagename'] + '/' + this.prevImageData['sizes'][albumData['large_size']]['size_filename']
        imageScrollerKey += this.prevImageData['filename']
        if (imageTempUrlCache[prevImagePath]) {
          previousImg = <img src={imageTempUrlCache[prevImagePath]} />
        }
      }
      if (this.nextImageData && siblingImagesLoaded) {
        const nextImagePath = albumData['pagename'] + '/' + this.nextImageData['sizes'][albumData['large_size']]['size_filename']
        imageScrollerKey += this.nextImageData['filename']
        if (imageTempUrlCache[nextImagePath]) {
          nextImg = <img src={imageTempUrlCache[nextImagePath]} />
        }
      }
      carousel = (<div
        className="slideshow-carousel"
        onClick={(e) => e.stopPropagation()}>
        <div
          className='slideshow-carousel-inner-container'
          onClick={(e) => e.stopPropagation()}
          onScroll={this.carouselScroll}
          ref={this.slideShowInnerContainer}
          >
          {this.renderImageCarousel()}
        </div>
      </div>)
    }
    // console.log(imageScrollerKey)
    let largeImageScroller = this.lastlargeImageScroller
    if (this.lastLargeImageData != largeImageData) {
      largeImageScroller = (
        <div key={imageScrollerKey} className="large-image-scroll">
          <div className={"large-image large-image-sibling"}>
            {previousImg}
          </div>
          <div className={"large-image current-image"}>
            {currentImg}
          </div>
          <div className={"large-image large-image-sibling"}>
            {nextImg}
          </div>
        </div>
      )
      if (largeImageSrc && siblingImagesLoaded) {
        this.lastLargeImageData = largeImageData
        this.lastlargeImageScroller = largeImageScroller
      }
    }
    return (
      <div className="slideshow-overlay" style={displayCss} onClick={this.hideSlideShow}>
        <div className={'slideshow-container'}>
          <div className="mobile-options-container">
            <div className="mobile-close" onClick={this.hideSlideShow}>
            </div>
          </div>
          <div className="large-image-scroll-container">
            {largeImageScroller}
          </div>
          <AlbumSlideShowLargeImagePanel
            imageData={largeImageData}
            albumData={albumData}
            addToSelection={this.props.addToSelection}
            removeFromSelection={this.props.removeFromSelection}
            deleteImages={this.props.deleteImages}
            selection={this.props.selection}
            downloadImage={this.props.downloadImage}
            />
        </div>
        {carousel}
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    albumKey: state.albumReducer.album_key,
    imageTempUrlCache: state.albumReducer.image_temp_url_cache
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addAlbumImageTmpUrl: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrl(albumImagePath, filename)),
    addAlbumImageTmpUrlReload: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrlReload(albumImagePath, filename))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumSlideShow)
const config = {
  pyalbum_host: 'https://images.worthphotos.com',
  // pyalbum_host: 'http://localhost:5000',
  // pyalbum_host: 'http://192.168.42.2:5000',
  // pyalbum_host: 'http://192.168.42.2:5000',
  max_selection: 10,
  userRolesOrder: [
    'viewer',
    'sharer',
    'editor',
    'admin'
  ]
}
export default config;